/* Flags rounding */
.flag-icon {
  border-radius: 0.125rem;
  -webkit-border-radius: 0.125rem;
  -moz-border-radius: 0.125rem;
  -ms-border-radius: 0.125rem;
  -o-border-radius: 0.125rem;
}

body {
  background-color: #f3f4f6;
  -webkit-appearance: none;
}

/* Tap highlight deactivation */
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}
